import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { INumberField } from "./INubmerField";
import { CopyIconButton } from "../../../../UI/Buttons/CopyIconButton/CopyIconButton";

export const NumberField: FC<INumberField> = ({ number }) => {

  console.log(number)
  return (
    <>
      {number !== null ? (
        <Stack direction={"row"} alignItems={"center"}>
          <Typography>{number}</Typography>
          <CopyIconButton copyText={number.toString()} />
        </Stack>
      ) : (
        <Typography>—выфвыфSDSADASDSADA</Typography>
      )}
    </>
  );
};

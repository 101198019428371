import { FC, useState } from "react";
import { ITableItem } from "../models/ITableItem";
import { Box, Checkbox, Link, TableCell, TableRow, Typography } from "@mui/material";
import { OpenInNew, VideoCameraFrontOutlined } from "@mui/icons-material";
import { ReleaseList } from "../../ReleaseList/ReleaseList";
import { ReleaseManager } from "./Fields/ReleaseManager/ReleaseManager";
import { PriceEdit } from "./Fields/PriceEdit/PriceEdit";
import { SocialType } from "./Fields/SocialType/SocialType";
import { PromokodField } from "./Fields/PromokodField/PromokodField";
import { changeTableColor } from "../shared/functions";
import { TheameField } from "./Fields/TheameField/TheameField";
import { PredictionField } from "./Fields/PredictionField/PredictionField";
import { GoodsField } from "./Fields/GoodsField/GoodsField";
import { DateEditField } from "./Fields/DateEditField/DateEditField";
import { IntegrationCategoryField } from "./Fields/IntegrationCategoryField/IntegrationCategoryField";
import { observer } from "mobx-react-lite";
import { PaymentChannelField } from "./Fields/PaymentChannelField/PaymentChannelField";
import { PaymentPriceField } from "./Fields/PaymentPriceField/PaymentPriceField";
import paymentsTableStore from "../../../store/paymentsTable.store";
import { StatusField } from "./Fields/StatusField/StatusField";
import { PaymentStatusIntegrationField } from "./Fields/PaymentStatusIntegrationField/PaymentStatusIntegrationField";
import { ChannelNumEditField } from "./Fields/ChannelNumEditField/ChannelNumEditField";
import { TechnicalTask } from "../../../pages/TableAsidePage/pages/Main/ui/TechnicalTask/TechnicalTask";
import { CompanyAccountField } from "./Fields/CompanyAccountField/CompanyAccountField";
import { CommentField } from "./Fields/CommentField/CommentField";
import { LinkField } from "./Fields/LinkField/LinkField";
import { priceTransormFormat } from "../../../shared/functions/functions";
import { ChannelPopUp } from "./Fields/ChannelPopUp/ChannelPopUp";
import { IntegrationPopUp } from "./Fields/IntegrationPopUp/IntegrationPopUp";
import { AgreementStatuses } from "./Fields/AgreementStatuses/AgreementStatuses";
import { ChannelPredictInput } from "../../../pages/ChannelCardPage/ui/ChannelAsidePredict/ui/ChannelPredictInput/ChannelPredictInput";
import { ChannelTextField } from "./Fields/ChannelTextField/ChannelTextField";
import { ChannelPriceEdit } from "./Fields/ChannelPriceEdit/ChannelPriceEdit";
import { AddPromokodField } from "./Fields/AddPromokodField/AddPromokodField";
import { AddReleaseField } from "./Fields/AddReleaseField/AddReleaseField";
import { AgreementComment } from "./Fields/AgreementComment/AgreementComment";
import { StatusEmptyField } from "./Fields/StatusEmptyField/StatusEmptyField";
import { TheameEmptyField } from "./Fields/TheameEmptyField/TheameEmptyField";
import { StatusPaymentEmptyField } from "./Fields/StatusPaymentEmptyField/StatusPaymentEmptyField";
import { ManagerField } from "./Fields/ManagerField/ManagerField";
import { BudgetEditField } from "./Fields/BudgetEditField/BudgetEditField";
import { ChannelDate } from "../../../pages/ChannelCardPage/ui/ChannelDate/ChannelDate";
import { GoodCategory } from "./Fields/GoodCategory/GoodCategory";
import { disableAuedienceOnType } from "../../../shared/functions/disableAuedienceOnType";
import { TableBurgerMenu } from "./TableBurgerMenu";
import { LastCommentField } from "./Fields/LastCommentField/LastCommentField";
import { RepeatModalField } from "./Fields/RepeatModalField/RepeatModalField";
import { TzTextField } from "./Fields/TzTextField/TzTextField";
import { TzSelectField } from "./Fields/TzSelectField/TzSelectField";
import { TzChannelSelect } from "./Fields/TzChannelSelect/TzChannelSelect";
import { TzGoodsSelect } from "./Fields/TzGoodsSelect/TzGoodsSelect";
import { CheckboxField } from "./Fields/CheckboxField/CheckboxField";
import ContrActorChannel from "./Fields/ContrActorChannel/ContrActorChannel";
import ArrayField from "./Fields/ArrayField/ArrayField";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { PaymentStatus } from "./Fields/PaymentStatus/PaymentStatus";
import { IntegrationPaymentDate } from "./Fields/IntegrationPaymentDate/IntegrationPaymentDate";
import { MotivationChannelnput } from "./Fields/MotivationChannelnput/MotivationChannelnput";
import { WrapperCellIcons } from "../../../pages/DashboardPage/pages/AgreementPage/ui/AgreementTableItem/ui/WrapperCellIcons/WrapperCellIcons";
import { GuaranteeField } from "./Fields/GuaranteeField/GuaranteeField";
import { GuaranteeCheckboxField } from "./Fields/GuaranteeCheckboxField/GuaranteeCheckboxField";
import { CheckboxFieldPayment } from "./Fields/CheckboxFieldPayment/CheckboxFieldPayment";
import { ProductSampleList } from "./Fields/ProductSampleList/ProductSampleList";
import { CategoryTriggerAccordion } from "./Fields/CategoryTriggerAccordion/CategoryTriggerAccordion";
import { EmptyText } from "../../EmptyText/EmptyText";
import { ControlButtonPanel } from "./ControlButtonPanel/ControlButtonPanel";
import { RemoveItemButton } from "../../UI/Buttons/RemoveItemButton/RemoveItemButton";
import { TzCheckbox } from "./Fields/TzCheckbox/TzCheckbox";
import { CopyIconButton } from "../../UI/Buttons/CopyIconButton/CopyIconButton";
import { CustomIconButton } from "../../UI/Buttons/CustomIconButton/CustomIconButton";
import { AddReleaseManager } from "../../../pages/ChannelCardPage/ui/AddReleaseManager/AddReleaseManager";
import { ChannelSize } from "./Fields/ChannelSize/ChannelSize";
import tableRowsStore from "../../../store/tableRows.store";
import { NumberField } from "./Fields/NubmerField/NumberField";

export const TableItem: FC<ITableItem> = observer((props) => {
  const {
    cells,
    showBurgerMenu = false,
    onChangeStatus,
    onUpdateStatus,
    burgerItemLists,
    isPriceEdit = false,
    hover = false,
    isStatusReadOnly,
    statusStore,
    readonly = false,
    showDeleteIcon,
    onDeleteItem,
    deleteDisabled,
    isSelected = false,
    onKeyDown,
    changeCallbackNumEdit,
    deleteIconSize = "medium",
    agreementStatusReadOnly = false,
    onChangeUpdateId,
    backgroundColor,
    agreementStatusCallback,
    showCheckboxCell = false,
    sx,
    allAllowedMoves,
    changePredictionNumEdit,
    readOnlyDateGiven,
    isBanned,
    enableCodesBurderItem,
    showDelete,
    lastCellContent,
    hiddenDateGivenEdit,
    isAccordion,
    onChangeType,
    borderColor,
    readOnlyChannelPrice,
    showAgreeIconsCell,
    tableRowId,
    searchBackgroundColor,
    isShowNavigateIcon,
    onNavigate,
    changePaymentInTable,
    hiddenDateGivenNotValueText,
    onChangePriceAndStatus,
    hiddenDateFoundEdit,
    isUsingChannelId = false,
    firstCellIcon,
    controlButtonPanel,
    changePaymentStatusInIntegrationTable,
    ...restProps
  } = props;

  const [selected, setSelected] = useState(false);

  const handleCheckBoxChange = () => {
    tableRowsStore.setCheckedRows(restProps.id);
    setSelected(!selected);
  };

  return (
    <TableRow
      id={`${tableRowId}`}
      hover={hover}
      onClick={onKeyDown ? (e: any) => onKeyDown(e, restProps["id"]) : undefined}
      selected={isSelected || selected}
      sx={{
        background: searchBackgroundColor
          ? "#cff4ff"
          : backgroundColor
          ? backgroundColor
          : !readonly
          ? "inherit"
          : "rgba(0, 0, 0, 0.025)",
        ...(isBanned && {
          "&.MuiTableRow-root:hover": {
            backgroundColor: "#ffdbe6",
          },
        }),
      }}>
      {showCheckboxCell && (
        <TableCell
          sx={{ p: 0, pl: 0, background: "inherit", ...sx }}
          onClick={(e) => e.stopPropagation()}>
          <Checkbox checked={selected} onChange={handleCheckBoxChange} />
        </TableCell>
      )}
      {isShowNavigateIcon && onNavigate && (
        <TableCell
          align="left"
          sx={{ p: 0, pl: 0, background: "inherit", ...sx }}
          onClick={(e) => e.stopPropagation()}>
          <CustomIconButton
            onClick={() =>
              isUsingChannelId ? onNavigate(restProps?.channel?.id) : onNavigate(restProps?.id)
            }>
            <OpenInNew />
          </CustomIconButton>
        </TableCell>
      )}
      {showBurgerMenu && !isAccordion && (
        <TableCell align="left" sx={{ p: "6px 8px", background: "inherit", ...sx }}>
          {burgerItemLists && (
            <TableBurgerMenu
              lists={burgerItemLists}
              restProps={restProps}
              isBanned={isBanned}
              update_id={restProps["update_id"]}
              enableCodesItem={enableCodesBurderItem}
              showDelete={showDelete}
            />
          )}
        </TableCell>
      )}
      {controlButtonPanel && (
        <TableCell align="left" sx={{ p: "6px 8px", background: "inherit", ...sx }}>
          <ControlButtonPanel
            lists={controlButtonPanel}
            restProps={restProps}
            isBanned={isBanned}
          />
        </TableCell>
      )}
      {showBurgerMenu && isAccordion && (
        <TableCell align="left" sx={{ p: "6px 8px", background: "inherit", ...sx }}>
          <KeyboardArrowRightIcon />
        </TableCell>
      )}
      {showAgreeIconsCell && (
        <TableCell
          sx={{ p: 0, pl: 0, background: "inherit", ...sx }}
          onClick={(e) => e.stopPropagation()}>
          <WrapperCellIcons
            userApprovments={restProps?.userApprovments}
            id={restProps?.agreementId}
          />
        </TableCell>
      )}
      {showDeleteIcon && (
        <TableCell align="center" sx={{ p: "6px 8px", ...sx }}>
          <RemoveItemButton
            size={deleteIconSize}
            disabled={deleteDisabled}
            onClick={onDeleteItem ? () => onDeleteItem(restProps) : undefined}
            sx={{ ml: 0, mr: 0 }}
          />
        </TableCell>
      )}
      {firstCellIcon && (
        <TableCell
          sx={{ p: 0, pl: 0, background: "inherit", ...sx }}
          onClick={(e) => e.stopPropagation()}>
          {firstCellIcon}
        </TableCell>
      )}
      {cells.map((cell, ind) => {
        if (cell) {
          if (restProps[cell.code] !== undefined && restProps[cell.code] !== null) {
            return (
              <TableCell
                key={cell.code + ind}
                sx={{
                  cursor: !readonly && hover ? "pointer" : cell?.immutable ? "not-allowed" : "auto",
                  background: cell.backgroundColor
                    ? cell.backgroundColor
                    : changeTableColor(cell.code, +restProps[cell.code], cell.isBackgroundColor),
                  p: "6px 8px",
                  minWidth: cell?.minWidth ? cell.minWidth : "auto",
                  maxWidth: cell.maxWidth ? cell.maxWidth : "inherit",
                  borderColor: borderColor,
                  ...sx,
                }}>
                {cell.isPomokod && restProps[cell.code].code ? (
                  <Box sx={{ display: "inline-block" }} onClick={(e) => e.stopPropagation()}>
                    <PromokodField
                      id={restProps["id"]}
                      promokod={restProps[cell.code].code}
                      dummies={restProps[cell.code].dummies}
                      integrationIsClone={restProps.isClone}
                      repeatIntegrationId={restProps.repeatIntegrationId}
                      isEditMode
                      readOnly={readonly}
                      isActivated={restProps[cell.code]?.isActivated}
                    />
                  </Box>
                ) : null}
                {cell.isIntegrationPaymentsDateEdit && cell.isDate && cell.fieldDataDateCode && (
                  <IntegrationPaymentDate
                    update_id={restProps?.payment?.update_id}
                    date={restProps[cell.code]}
                    field={cell.code}
                    oplataId={restProps?.payment?.id}
                    readOnly={readonly}
                    integrationId={restProps?.id}
                    field_data_code={cell.fieldDataDateCode}
                  />
                )}
                {cell.isRelease && (
                  <AddReleaseManager
                    onChangeManager={restProps.onChangeManager}
                    onChange={restProps.onChange}
                    placeholder={cell.placeholder}
                    id={restProps?.id}
                    update_id={restProps.update_id}
                    field={cell.code}
                    fieldNotCode={restProps.fieldNotCode}
                    readOnly={readonly || restProps.readOnly}
                    initValue={restProps?.releaseBy?.id}
                    isDeleteIcon={cell.isDeleteIcon}
                  />
                )}
                {cell.isDate &&
                  !cell.isIntegrationDatesEdit &&
                  !cell.isIntegrationPaymentsDateEdit && (
                    <>
                      {!cell.isDateEdit ? (
                        <DateEditField
                          transferStatus={
                            cell.code === "dateGiven" && restProps.transfer?.status?.code
                          }
                          id={restProps["id"]}
                          update_id={restProps["update_id"]}
                          field={cell.code}
                          date={restProps[cell.code]}
                          readonly={readonly ? true : !readonly || cell.readOnly!}
                          isFullYears={cell.isFullYears}
                        />
                      ) : (
                        <ChannelDate
                          id={restProps["id"]}
                          initValue={restProps[cell.code]}
                          update_id={restProps["update_id"]}
                          readOnly={cell.code === "dateGiven" ? readOnlyDateGiven : readonly}
                          hiddenEdit={
                            cell.code === "dateGiven"
                              ? hiddenDateGivenEdit
                              : cell.code === "dateFound"
                              ? hiddenDateFoundEdit
                              : false
                          }
                          field={cell.code}
                        />
                      )}
                    </>
                  )}
                {cell.isDate && cell.isIntegrationDatesEdit && (
                  <DateEditField
                    id={restProps["id"]}
                    update_id={restProps["update_id"]}
                    field={cell.code}
                    date={restProps[cell.code]}
                    readonly={readonly || cell.readOnly!}
                    isFullYears={cell.isFullYears}
                    isIntegrationDates={cell.isIntegrationDatesEdit}
                  />
                )}
                {cell.isReleases ? (
                  restProps[cell.code].length > 0 ? (
                    <Box onClick={(e) => e.stopPropagation()}>
                      <ReleaseList
                        id={restProps["id"]}
                        array={restProps[cell.code]}
                        readOnly={readonly}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ display: "inline-block" }} onClick={(e) => e.stopPropagation()}>
                      <AddReleaseField
                        type={restProps["channel"]?.type?.code}
                        id={restProps["id"]}
                        readOnly={readonly}
                      />
                    </Box>
                  )
                ) : null}
                {cell.isStatuses ? (
                  <>
                    <Box onClick={(e) => e.stopPropagation()}>
                      {cell.isIntegrationPaymentStatus !== true &&
                      statusStore &&
                      onChangeStatus &&
                      onUpdateStatus ? (
                        <StatusField
                          id={restProps["id"] as number}
                          update_id={restProps["update_id"] as number}
                          type={restProps[cell.code].code}
                          statusStore={statusStore}
                          readOnly={
                            isStatusReadOnly
                              ? true
                              : restProps.channel?.govVerification
                              ? !restProps.channel.govVerification.isVerifiedByGov
                              : readonly
                          }
                          onChangeStatus={onChangeStatus}
                          onChange={restProps.onChange}
                          onUpdateStatus={onUpdateStatus}
                          integrationId={restProps?.id}
                          allAllowedMoves={allAllowedMoves}
                          onChangeType={onChangeType}
                          isVerifiedByGov={
                            restProps.channel?.govVerification
                              ? restProps.channel.govVerification.isVerifiedByGov
                              : restProps.govVerification.isVerifiedByGov
                          }
                        />
                      ) : null}
                      {cell.isIntegrationPaymentStatus && (
                        <PaymentStatusIntegrationField
                          changePaymentStatusInIntegrationTable={
                            changePaymentStatusInIntegrationTable
                          }
                          integrationId={restProps?.id}
                          id={restProps?.payment?.id}
                          type={restProps[cell.code].code}
                          update_id={restProps?.payment?.update_id}
                          readOnly={readonly}
                          isVerifiedByGov={restProps.channel.govVerification.isVerifiedByGov}
                        />
                      )}
                    </Box>
                  </>
                ) : null}
                {cell.isStatuses && isStatusReadOnly && statusStore && (
                  <StatusField
                    id={restProps["id"] as number}
                    type={restProps[cell.code].code}
                    statusStore={statusStore}
                    readOnly={isStatusReadOnly}
                    onChangeStatus={() => ""}
                    update_id={1}
                    onUpdateStatus={() => "" as any}
                  />
                )}
                {cell.statusReadOnly && (
                  <Box
                    sx={{
                      p: "6px 8px",
                      borderRadius: "4px",
                      textAlign: "center",
                      background: restProps["statusColor"],
                      whiteSpace: "nowrap",
                    }}>
                    <Typography variant="body1" color={"#fff"}>
                      {restProps[cell.code]}
                    </Typography>
                  </Box>
                )}
                {cell.isIntegrationPayment && cell.fieldCode && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography noWrap>
                      {restProps[cell.code]?.ordInfo?.[0]?.[cell.fieldCode]
                        ? restProps[cell.code]?.ordInfo?.[0]?.[cell.fieldCode]
                        : "—"}
                    </Typography>
                    {restProps[cell.code]?.ordInfo?.[0]?.[cell.fieldCode] && (
                      <CopyIconButton
                        copyText={restProps[cell.code]?.ordInfo?.[0]?.[cell.fieldCode!]}
                      />
                    )}
                  </Box>
                )}
                {cell.isPaymentTableStatus && (
                  <PaymentStatus
                    id={restProps?.id}
                    statusCode={restProps[cell.code]?.code}
                    type="payment"
                    readOnly={cell.paymentStatusReadOnly || readonly}
                    wrap
                    onChangeStatus={changePaymentInTable}
                    update_id={restProps?.update_id}
                  />
                )}
                {cell.isPaymentCheckbox && cell.fieldCode && (
                  <CheckboxFieldPayment
                    integrationId={restProps?.id}
                    checked={restProps?.payment?.[cell.fieldCode]}
                    field={cell.fieldCode}
                    id={restProps?.payment?.id}
                    readOnly={readonly}
                    update_id={restProps?.payment?.update_id}
                  />
                )}
                {cell.isAgreementStatus && (
                  <AgreementStatuses
                    agreementId={restProps?.id}
                    statusCode={restProps?.approvedStatus?.code}
                    userId={restProps?.userId}
                    readonly={agreementStatusReadOnly || readonly}
                    agreementStatusCallback={agreementStatusCallback}
                  />
                )}
                {cell.isChannel && (
                  <Box onClick={(e) => e.stopPropagation()}>
                    {cell.code !== "channel" ? (
                      <ChannelPopUp
                        showArrowLink={cell.showArrowLink}
                        name={restProps?.name}
                        link={restProps?.link}
                        channelType={restProps?.type?.code}
                        channelId={restProps?.id}
                        update_id={restProps?.update_id}
                        readOnly={readonly}
                        isBanned={isBanned || restProps?.isBanned}
                        showPaymentOnCardIcon={restProps.isPaymentOnCard || false}
                        showVerifiedIcon={restProps.govVerification.isVerifiedByGov || false}
                        audience={restProps.audience}
                      />
                    ) : (
                      <IntegrationPopUp
                        name={restProps[cell.code]?.name}
                        link={restProps[cell.code]?.link}
                        channelType={restProps[cell.code]?.type?.code}
                        showChannelButton
                        channelId={restProps[cell.code]?.id}
                        update_id={restProps[cell.code]?.update_id}
                        channelName={restProps[cell.code]?.name}
                        readOnly={readonly}
                        isBanned={restProps?.channel?.isBanned}
                        showCopy={restProps?.showCopy}
                        showIcon={restProps?.showIcon}
                        width={restProps?.width}
                        showVideoReviewIcon={restProps?.isVideoReview || false}
                        showPaymentOnCardIcon={restProps.isPaymentOnCard || false}
                        showVerifiedIcon={
                          restProps[cell.code]?.govVerification
                            ? restProps[cell.code]?.govVerification.isVerifiedByGov
                            : false
                        }
                        audience={restProps[cell.code]?.audience}
                      />
                    )}
                  </Box>
                )}
                {cell.isIntegrationChannel && (
                  <PaymentChannelField
                    id={restProps["integration"].id}
                    channel={restProps["integration"].channel}
                  />
                )}
                {cell.isChannelPredictionInput && changePredictionNumEdit && (
                  <ChannelPredictInput
                    id={restProps?.id}
                    field={cell.code}
                    initValue={restProps[cell.code]}
                    update_id={restProps["update_id"]}
                    readOnly={readonly}
                    changeCallbackNumEdit={changePredictionNumEdit}
                    symbol={cell?.ctrlsIcon}
                    showRemains={cell.isShowRemains ? true : false}
                  />
                )}
                {cell.isContrActorChannel && <ContrActorChannel channel={restProps[cell.code]} />}
                {cell.isIntegrationPaidSumm && (
                  <Typography sx={{ whiteSpace: "nowrap" }}>
                    {priceTransormFormat(restProps[cell.code])}
                  </Typography>
                )}
                {cell.isMotivationChannelPrice && (
                  <MotivationChannelnput
                    id={restProps[cell.code + "Id"]}
                    value={restProps[cell.code]}
                    readOnly={readOnlyChannelPrice}
                  />
                )}
                {cell.isFirstIntegrationWithChannel !== undefined && (
                  <Typography>{restProps[cell.code] === true ? "Новый" : "Старый"}</Typography>
                )}
                {cell.isLinks && (
                  <LinkField
                    link={cell?.href ? cell.href(restProps["id"]) : restProps[cell.code]}
                    maxWidth={cell.maxWidth}
                  />
                )}
                {cell.isContractLink && (
                  <>
                    {restProps[cell.code].link ? (
                      <Link
                        href={cell?.href ? cell.href(restProps["id"]) : restProps[cell.code].link}
                        target="_blank"
                        sx={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: cell.maxWidth ? cell.maxWidth : "180px",
                          display: "block",
                        }}>
                        {cell?.href ? cell.href(restProps["id"]) : restProps[cell.code].number}
                      </Link>
                    ) : (
                      <Typography noWrap>{restProps[cell.code].number}</Typography>
                    )}
                  </>
                )}
                {cell.isGuarantee && (
                  <GuaranteeField
                    isGuaranteed={Boolean(restProps?.guarantee?.repeat)}
                    color={restProps[cell.code]?.status?.color}
                  />
                )}
                {cell.isGuaranteeCheckbox && (
                  <GuaranteeCheckboxField checked={Boolean(restProps?.guarantee?.repeat)} />
                )}
                {cell.isCategory && (
                  <IntegrationCategoryField
                    id={restProps["id"]}
                    update_id={restProps["update_id"]}
                    category={restProps[cell.code]}
                    readOnly={readonly}
                  />
                )}
                {cell.isCategoryGood && <Typography>{restProps["goodsCategory"].name}</Typography>}
                {cell.isCategoryGoodArrayIcon && (
                  <CategoryTriggerAccordion
                    isOpen={restProps.openRows[restProps.index]}
                    nameCategory={restProps[cell.code].name}
                    quantity={restProps.integrations.length}
                  />
                )}
                {cell.isGoods && (
                  <Box onClick={(e) => e.stopPropagation()}>
                    <GoodsField
                      id={restProps["id"]}
                      goods={restProps[cell.code]}
                      readOnly={readonly}
                      forFirstIntegrationWithChannel={restProps?.firstIntegrationWithChannel}
                    />
                  </Box>
                )}
                {cell.isTzSimpleSelect && (
                  <TzSelectField
                    isEditIcon={cell.isEditIcon}
                    field={cell.code}
                    id={restProps.id}
                    initValue={restProps[cell.code]}
                    update_id={restProps["update_id"]}
                  />
                )}
                {cell.isTzSimple && (
                  <TzTextField
                    id={restProps.id}
                    field={cell.code}
                    initValue={restProps[cell.code]}
                    update_id={restProps["update_id"]}
                    isCopy={cell.isTzCopy}
                    isEdit={cell.isEdit}
                    isLink={cell.isTzLinks}
                  />
                )}
                {cell.isTzSelectChannel && (
                  <TzChannelSelect
                    id={restProps.id}
                    field={cell.code}
                    initValue={restProps[cell.code]}
                    update_id={restProps["update_id"]}
                  />
                )}
                {cell.isTzSelectGoods && (
                  <TzGoodsSelect
                    isEditIcon={cell.isEditIcon}
                    id={restProps.id}
                    field={cell.code}
                    initValue={restProps[cell.code]}
                    update_id={restProps["update_id"]}
                  />
                )}
                {cell.isRepeatIntegration && <RepeatModalField id={restProps["id"]} />}
                {cell.isGoodCategory && <Typography>{restProps[cell.code]?.name}</Typography>}
                {cell.isGoodCategoryCode && <GoodCategory code={restProps[cell.code]} />}
                {cell.isSimpleCheckbox && (
                  <Checkbox checked={restProps[cell.code]} onChange={() => ""} />
                )}
                {cell.isChannelProductSample && cell?.fieldCode && (
                  <SocialType
                    code={restProps[cell.code]?.[cell?.fieldCode]?.type?.code}
                    text={restProps[cell.code]?.[cell?.fieldCode]?.name || undefined}
                  />
                )}
                {cell.isPromokodProductSample && cell?.fieldCode && (
                  <Typography sx={{ whiteSpace: "nowrap", display: "flex" }}>
                    {restProps[cell.code]?.id} /{" "}
                    {restProps[cell.code]?.[cell?.fieldCode]?.code || <EmptyText />}
                  </Typography>
                )}
                {cell.isProductSampleList && cell.fieldCode && (
                  <ProductSampleList list={restProps[cell?.fieldCode]} />
                )}
                {cell.isPredictions && (
                  <PredictionField
                    id={restProps["id"]}
                    field_code={cell.code}
                    initValue={restProps[cell.code]}
                    update_id={restProps["update_id"]}
                    readOnly={readonly || cell.readOnly ? true : false}
                    onChangeUpdateId={onChangeUpdateId}
                    symbol={cell.isPercent ? "%" : undefined}
                    showRemains={cell.isShowRemains ? true : false}
                  />
                )}
                {cell.isTheme && (
                  <TheameField
                    shouldHideSelect
                    {...restProps[cell.code]}
                    id={restProps["id"]}
                    update_id={restProps["update_id"]}
                    readOnly={readonly}
                  />
                )}
                {cell.isContact && (
                  <ChannelTextField
                    id={restProps["id"]}
                    field={cell.code}
                    update_id={restProps["update_id"]}
                    initValue={restProps[cell.code] ? restProps[cell.code] : null}
                    readOnly={readonly}
                  />
                )}
                {cell.isNumber && <NumberField number={restProps.govNumber} />}
                {cell.isGovVerification && !cell.isNumber && <LinkField link={restProps.govLink} />}
                {cell.isLastComments ? (
                  <>
                    {restProps[cell.code]?.length > 0 ? (
                      <LastCommentField
                        id={restProps?.id}
                        text={restProps[cell.code][restProps[cell.code]?.length - 1]?.text}
                        comments={restProps[cell.code]}
                      />
                    ) : (
                      <Typography sx={{ display: "inline-flex", verticalAlign: "super" }}>
                        —
                      </Typography>
                    )}
                  </>
                ) : null}
                {cell.isType && (
                  <SocialType
                    code={restProps[cell.code]?.code}
                    text={cell.isShowTypeText ? restProps[cell.code]?.name : undefined}
                    showPaymentOnCardIcon={restProps.isPaymentOnCard || false}
                  />
                )}
                {cell.isChannelType && (
                  <SocialType
                    code={restProps[cell.code]?.type?.code}
                    text={cell.isShowTypeText ? restProps[cell.code]?.name : undefined}
                    showVideoReviewIcon={restProps?.isVideoReview || false}
                    showPaymentOnCardIcon={restProps.isPaymentOnCard || false}
                    showVerifiedIcon={
                      restProps[cell.code].govVerification
                        ? restProps[cell.code]?.govVerification.isVerifiedByGov
                        : false
                    }
                    audience={restProps[cell.code]?.audience || 0}
                  />
                )}
                {cell.isReleaseUsers ? (
                  <Box sx={{ float: "left" }}>
                    <ReleaseManager isMenu={cell.isMenu} id={restProps[cell.code].id} />
                  </Box>
                ) : null}
                {cell.isSize && (
                  <Typography>
                    {cell.fieldCode
                      ? restProps?.[cell.code]?.[cell.fieldCode]?.name
                      : restProps[cell.code]?.name}
                  </Typography>
                )}
                {cell.isChannelSize && <ChannelSize color={restProps.size.code} />}
                {cell.isManagerUser && <ManagerField id={restProps["userId"]} />}
                {cell.isBudget && (
                  <BudgetEditField
                    initValue={restProps["amount"]}
                    month={restProps["month"]}
                    userId={restProps["userId"]}
                    year={restProps["year"]}
                    budgetType={restProps["budgetType"]}
                    field={restProps["field"]}
                  />
                )}
                {cell.isMoney && (
                  <>
                    {cell.isChannelPriceEdit === false ? (
                      <PriceEdit
                        id={restProps["id"]}
                        price={restProps[cell.code]}
                        field={cell.code}
                        update_id={restProps["update_id"]}
                        minWidth="200px"
                        isEditMode={cell.readOnly ? false : isPriceEdit && !readonly}
                        showRemains={cell.isShowRemains}
                        onChangePriceAndStatus={onChangePriceAndStatus}
                        isIntegrationClone={restProps.isClone}
                      />
                    ) : (
                      <ChannelPriceEdit
                        id={restProps["id"]}
                        value={restProps[cell.code]}
                        field={cell.code}
                        update_id={restProps["update_id"]}
                        readOnly={readonly}
                      />
                    )}
                  </>
                )}
                {cell.isPaymentPrice && (
                  <PaymentPriceField
                    id={restProps["id"]}
                    field={cell.code}
                    price={restProps[cell.code]}
                    readOnly={cell.readOnly!}
                    changeCommission={
                      cell.code === "commission" ? paymentsTableStore.changeCommission : undefined
                    }
                  />
                )}
                {cell.isCheckList && (
                  <CheckboxField
                    id={restProps?.id}
                    field={cell.code}
                    update_id={restProps["update_id"]}
                    checked={restProps[cell.code]}
                    readOnly={readonly}
                  />
                )}
                {cell.isIntegrationTheame && (
                  <Typography
                    noWrap
                    sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                    {restProps?.channel?.theme?.name || "—"}
                  </Typography>
                )}
                {cell.isCompanyAccount && (
                  <CompanyAccountField
                    id={restProps["id"]}
                    code={restProps["companyAccount"].code}
                    name={restProps["companyAccount"].name}
                  />
                )}
                {cell.tzCheckbox && (
                  <TzCheckbox
                    checked={restProps[cell.code]}
                    field={cell.code}
                    id={restProps.id}
                    update_id={restProps["update_id"]}
                    readOnly={readonly}
                  />
                )}
                {cell.isTz && (
                  <TechnicalTask
                    goods={restProps?.goods}
                    goodsCategoryCode={restProps?.goodsCategory?.code}
                    integrationId={restProps["id"]}
                    update_id={restProps["update_id"]}
                    readOnly={readonly || cell.readOnly}
                    iniValue={{
                      id: restProps[cell.code]?.id,
                      link: restProps[cell.code]?.link,
                      name: restProps[cell.code]?.name,
                      allowUsage: restProps[cell.code]?.allowUsage,
                      goodsCategoryCode: restProps[cell.code]?.goodsCategoryCode,
                    }}
                    minWidth={175}
                    channelType={restProps["channel"]?.type?.code}
                  />
                )}
                {cell.isChangeNum && (
                  <ChannelNumEditField
                    field={cell.code}
                    id={restProps["id"]}
                    initValue={restProps[cell.code]}
                    update_id={restProps["update_id"]}
                    onResCallback={changeCallbackNumEdit}
                    readOnly={readonly || cell.readOnly}
                    symbol={cell.isPercent ? "%" : ""}
                    disabled={
                      cell.isAudience
                        ? disableAuedienceOnType(restProps["type"]?.code, cell.code)
                        : undefined
                    }
                  />
                )}
                {cell.isSimpleInObjectCode && <Typography>{restProps[cell.code]?.name}</Typography>}
                {cell.isSimpleView && (
                  <Typography>{priceTransormFormat(restProps[cell.code], false)}</Typography>
                )}
                {cell.isCtrls ? (
                  <Typography sx={{ minWidth: cell.minWidth }} noWrap>
                    {priceTransormFormat(
                      restProps[cell.code],
                      false,
                      cell.isShowRemains ? true : false,
                      cell.isShowRemains ? true : false
                    )}{" "}
                    {cell.ctrlsIcon}
                  </Typography>
                ) : null}
                {cell.isWritting && cell.isSimple === false && (
                  <AgreementComment
                    value={restProps["comment"]}
                    userId={restProps["userId"]}
                    agreementId={restProps["id"]}
                  />
                )}
                {cell.isSimple && cell.isMoneySimple && (
                  <Typography noWrap>
                    {priceTransormFormat(
                      restProps[cell.code],
                      true,
                      cell.isMoneyShowPredictionRemains || false,
                      cell.isMoneyShowPredictionRemains || false
                    )}
                  </Typography>
                )}
                {cell.isArray && (
                  <ArrayField readOnly={readonly} restProps={restProps} cellProps={cell} />
                )}
                {cell.isSimple &&
                cell.code !== "comment" &&
                cell.isWritting !== true &&
                cell.isMoneySimple !== true ? (
                  <>
                    <Typography noWrap>
                      {cell.showSimpleVideoReviewIcon && restProps?.isVideoReview === true && (
                        <VideoCameraFrontOutlined sx={{ verticalAlign: "top", mr: 0.5 }} />
                      )}
                      {cell.isShowRemains === false
                        ? Math.round(restProps[cell.code])
                        : restProps[cell.code]}{" "}
                      {cell.isPercent && " %"} {cell.ctrlsIcon ? cell.ctrlsIcon : null}
                    </Typography>
                    {cell.isSimpleCopy && <CopyIconButton copyText={restProps[cell.code]} />}
                  </>
                ) : (
                  <>
                    {cell.code === "comment" && cell.isWritting !== true ? (
                      <CommentField
                        text={restProps[cell.code]}
                        subtext={cell.isPercent ? " %" : ""}
                      />
                    ) : null}
                  </>
                )}
              </TableCell>
            );
          } else {
            if (cell.code === "category" && cell.isCategory && !readonly === false) {
              return (
                <TableCell
                  key={cell.title}
                  sx={{ background: "inherit", p: "6px 8px", maxWidth: cell.maxWidth, ...sx }}>
                  <IntegrationCategoryField
                    id={restProps["id"]}
                    update_id={restProps["update_id"]}
                    category={restProps[cell.code]}
                    readOnly={readonly}
                  />
                </TableCell>
              );
            } else if (
              cell.isChangeNum &&
              restProps[cell.code] === null &&
              !readonly &&
              !cell.readOnly
            ) {
              return (
                <TableCell key={cell.title} sx={{ background: "inherit", p: "6px 8px", ...sx }}>
                  <ChannelNumEditField
                    field={cell.code}
                    id={restProps["id"]}
                    initValue={restProps[cell.code]}
                    update_id={restProps["update_id"]}
                    onResCallback={changeCallbackNumEdit}
                    disabled={
                      cell.isAudience
                        ? disableAuedienceOnType(restProps["type"]?.code, cell.code)
                        : undefined
                    }
                  />
                </TableCell>
              );
            } else if (cell.isTz && restProps[cell.code] === null) {
              return (
                <TableCell key={cell.title} sx={{ background: "inherit", p: "6px 8px", ...sx }}>
                  <TechnicalTask
                    integrationId={restProps["id"]}
                    update_id={restProps["update_id"]}
                    readOnly={readonly || cell.readOnly}
                    iniValue={null}
                    minWidth={200}
                    channelType={restProps["channel"]?.type?.code}
                  />
                </TableCell>
              );
            } else if (
              cell.isStatuses &&
              restProps[cell.code] === null &&
              onChangeStatus &&
              onUpdateStatus
            ) {
              return (
                <TableCell key={cell.title} sx={{ background: "inherit", p: "6px 8px", ...sx }}>
                  <Box sx={{ display: "inline-block" }} onClick={(e) => e.stopPropagation()}>
                    <StatusEmptyField
                      id={restProps["id"]}
                      update_id={restProps["update_id"]}
                      statusStore={statusStore as any}
                      onChangeStatus={onChangeStatus}
                      onUpdateStatus={onUpdateStatus}
                      readOnly={isStatusReadOnly ? true : readonly}
                    />
                  </Box>
                </TableCell>
              );
            } else if (cell.isStatuses && restProps[cell.code] === null && cell.isPaymentStatus) {
              return (
                <TableCell
                  key={cell.title}
                  sx={{ background: "inherit", p: "6px 8px", ...sx, borderColor: borderColor }}>
                  <StatusPaymentEmptyField
                    id={restProps["id"]}
                    readOnly={isStatusReadOnly ? true : readonly}
                  />
                </TableCell>
              );
            } else if (cell.isContact && restProps[cell.code] === null) {
              return (
                <TableCell
                  key={cell.title}
                  sx={{ background: "inherit", p: "6px 8px", ...sx, borderColor: borderColor }}>
                  <ChannelTextField
                    id={restProps["id"]}
                    field={cell.code}
                    update_id={restProps["update_id"]}
                    initValue={null}
                    readOnly={readonly}
                  />
                </TableCell>
              );
            } else if (cell.code === "releaseUser" || cell.code === "releaseBy") {
              return (
                <TableCell
                  key={cell.title}
                  sx={{ p: "6px 8px", background: "inherit", ...sx, borderColor: borderColor }}>
                  <Typography
                    sx={{ display: "inline-flex", verticalAlign: "super", float: "left" }}>
                    —
                  </Typography>
                </TableCell>
              );
            } else if (cell.isTheme && restProps[cell.code] === null) {
              return (
                <TableCell
                  key={cell.title}
                  sx={{ p: "6px 8px", background: "inherit", ...sx, borderColor: borderColor }}>
                  <Box sx={{ display: "inline-block" }} onClick={(e) => e.stopPropagation()}>
                    <TheameEmptyField
                      {...restProps[cell.code]}
                      id={restProps["id"]}
                      update_id={restProps["update_id"]}
                      readOnly={readonly}
                    />
                  </Box>
                </TableCell>
              );
            } else if (cell.isPomokod && restProps[cell.code] === null) {
              return (
                <TableCell
                  key={cell.title}
                  sx={{ p: "6px 8px", background: "inherit", ...sx, borderColor: borderColor }}>
                  <Box sx={{ display: "inline-block" }} onClick={(e) => e.stopPropagation()}>
                    <AddPromokodField id={restProps["id"]} readOnly={readonly} />
                  </Box>
                </TableCell>
              );
            } else if (
              cell.isDate &&
              cell.isDateEdit &&
              cell.isSimple === false &&
              cell.isIntegrationDatesEdit !== true
            ) {
              return (
                <TableCell
                  key={cell.title}
                  sx={{ p: "6px 8px", background: "inherit", ...sx, borderColor: borderColor }}>
                  <ChannelDate
                    id={restProps["id"]}
                    initValue={null}
                    update_id={restProps["update_id"]}
                    readOnly={cell.code === "dateGiven" ? readOnlyDateGiven : readonly}
                    hiddenEdit={cell.code === "dateGiven" ? hiddenDateGivenEdit : false}
                    isNotValueTextHidden={hiddenDateGivenNotValueText}
                    field={cell.code}
                  />
                </TableCell>
              );
            } else if (
              cell.isWritting &&
              restProps["comment"] === null &&
              cell.isSimple === false
            ) {
              return;
            } else if (cell.isMoney && cell.isChannelPriceEdit && restProps[cell.code] === null) {
              return (
                <TableCell
                  key={cell.title}
                  sx={{ p: "6px 8px", background: "inherit", ...sx, borderColor: borderColor }}>
                  <ChannelPriceEdit
                    id={restProps["id"]}
                    value={restProps[cell.code]}
                    field={cell.code}
                    update_id={restProps["update_id"]}
                    readOnly={readonly}
                  />
                </TableCell>
              );
            } else if (cell.isTzSimpleSelect) {
              return (
                <TableCell
                  key={cell.title}
                  sx={{
                    p: "6px 8px",
                    background: "inherit",
                    verticalAlign: "center",
                    ...sx,
                    borderColor: borderColor,
                  }}>
                  <TzSelectField
                    isEditIcon={cell.isEditIcon}
                    field={cell.code}
                    id={restProps.id}
                    initValue={null}
                    update_id={restProps?.update_id}
                  />
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  key={cell.title}
                  sx={{ p: "6px 8px", background: "inherit", ...sx, borderColor: borderColor }}>
                  <Typography>—</Typography>
                </TableCell>
              );
            }
          }
        } else return;
      })}
      {lastCellContent && (
        <TableCell sx={{ p: "6px 8px", background: "inherit", ...sx, borderColor: borderColor }}>
          {lastCellContent}
        </TableCell>
      )}
    </TableRow>
  );
});
